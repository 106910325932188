import React, { useEffect, useState } from 'react';
import '../../App.css'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Highcharts from 'highcharts';
import highcharts3d from "highcharts/highcharts-3d";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import revise from "../../images/logos/revise.png"
import { ActionComponent } from './Action';
import BigPieChart from '../Grafici/BigPieChart';
import BarChart from '../Grafici/BarChart';
import ChartItem2 from './ChartItem2';
import { CustomWidthTooltip } from '../../styles/Customtooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Report, Action } from '../../Integration/models';
import * as client from '../../Integration/api';
import { ClipLoader } from 'react-spinners';
import { Configuration } from '../../Integration';
import { ROLE_ADMIN, ROLE_USER, OPENES_URL } from "../../costants";
import { Accuracy } from './Accuracy';

interface props {
    setWarning: React.Dispatch<React.SetStateAction<string>>,
    completedSteps: number,
    setCompletedSteps: React.Dispatch<React.SetStateAction<number>>,

}

ChartJS.register(ArcElement, Tooltip, Legend);
highcharts3d(Highcharts);

export const ReportComponent: React.FC<props> = ({ setWarning, completedSteps, setCompletedSteps }) => {


    const navigate = useNavigate();
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
    const [report, setReport] = React.useState<Report>()
    const [actions, setActions] = React.useState<Action[]>([])
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)
    const [generatedPDF, setGeneratedPDF] = useState<boolean>(false)
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const token = sessionStorage.getItem("sessionToken")
    const role = sessionStorage.getItem("role")
    const gradazioniDiBlu = ['linear-gradient(180deg, #007CC1, #013466 20%)', 'linear-gradient(180deg, #007CC1, #0252A2 30%)', 'linear-gradient(180deg, #007CC1, #0366C7 50%)', 'linear-gradient(180deg, #007CC1, #0483E0 70%)', 'linear-gradient(180deg, #007CC1, #05A3FF 90%)'];

    if (completedSteps < 6) setCompletedSteps(6)

    useEffect(() => {
        setCurrentLanguage(i18n.language)
    }, [i18n.language]);


    useEffect(() => {
        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)
        let reportYear = sessionStorage.getItem("reportYear")
        if (loading && reportYear) {
            clientAPI.getReportForYear(reportYear).then(res => {
               
                setLoading(false)
                setReport(res.data)
                setActions(res.data.actions)
                //generatePDF()

            }).catch(error => {
                console.error(error)
                if (error.response && error.response.status === 403) {
                    setWarning("session Expired")
                    setTimeout(() => {
                        navigate("/openES");
                    }, 2000);
                }
            })
        }

    }, []);

    const handleDownload = async () => {

        const conf: Configuration = {
            accessToken: token ? token : "",
            baseOptions: {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            },
        };
        const clientAPI = new client.DefaultApi(conf)
        let reportYear = sessionStorage.getItem("reportYear")
        if (reportYear) {
            setLoadingPDF(true)
            clientAPI.downloadReportPDF(reportYear, currentLanguage).then(response => {
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = "report" + "_" + report?.year + ".pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
                setLoadingPDF(false)
            }).catch(error => {
                console.error(error)
            });

        }


    }


    return (
        <>
            <div style={{ background: "#F5F5F5" }}>

                <Grid
                    container
                    direction="column"
                    display='flex'
                    justifyContent="center"
                    alignItems="center"
                    gap="5vh"
                    style={{ minHeight: "100vh", marginBottom: "7vh" }}
                >
                    {loading ? (
                        <ClipLoader size={150} color={'#007BFF'} loading={loading} />
                    ) : (

                        <>
                            <Paper square elevation={3} sx={{ display: "flex", backgroundColor: "white", textAlign: 'center', borderRadius: '50px', paddingInline: "1rem", flexDirection: "column", marginTop: "14vh" }} >
                                <p>{report?.ragioneSociale}</p>
                            </Paper>
                            <Paper square elevation={3} sx={{ backgroundColor: "white", borderRadius: '50px', width: "80vw", flexDirection: "column", alignItems: "center", padding: "2rem", gap: "2vh", border: "inset 1px solid whitesmoke", marginTop: "-2vh" }}  >

                                <Grid
                                    container
                                    direction="row"
                                    display='flex'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    marginTop='-4vh'
                                >
                                    <Grid item xs={4} justifyContent={'flex-start'}>
                                        <Button
                                            variant="navigation"
                                            onClick={e => handleDownload()}
                                            style={{ width: "7vw", margin: "0" }}
                                        >
                                            {loadingPDF ? (
                                                <ClipLoader color={'#ffffff'} size={20} loading={loadingPDF} />
                                            ) : (
                                                <p>Download</p>
                                            )
                                            }
                                        </Button>
                                    </Grid>

                                    <Grid item xs={4} justifyContent={'center'}>
                                        <p style={{ color: "#007CC1", fontWeight: 'bold', textAlign: 'center', fontSize: '1.2vw', margin: '0' }}>
                                            {t("label5Report")} {report?.year}
                                        </p>
                                    </Grid>
                                    <Grid item xs={4} justifyContent={'flex-end'} display={'flex'}>

                                        {/* <p style={{ margin: '0', textAlign: 'center', fontSize: "0.6vw" }}>{t("label4Report")} = {t(report?.accuracy ? report?.accuracy : "")}</p> */}
                                        <Accuracy accuracy={report?.accuracy ? report?.accuracy : ""} text2={t(report?.accuracy ? report?.accuracy : "")} text1={t("accuracy")} ></Accuracy>

                                    </Grid>
                                </Grid>

                                <Divider light ></Divider>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" gap="1vw" marginTop="2vh">
                                    {/* Sezione Grafico a Torta, Riquadri e Scritta */}
                                    <Grid container direction="column" alignItems="center" justifyContent="flex-start" width="auto" gap="1vh">
                                      {/* Contenitore per Grafico a Torta e Riquadri */}
                                      <Grid container direction="row" alignItems="center" justifyContent="flex-start" gap="1vw">
                                        {/* Grafico a Torta */}
                                        <Grid item width="17vw" style={{ marginTop: "2vh" }}>
                                          <BigPieChart scope1={report?.scope1.tonCo2 ? parseFloat(report?.scope1.tonCo2) : 0} scope2={report?.scope2.tonCo2 ? parseFloat(report?.scope2.tonCo2) : 0} />
                                        </Grid>
                                        {/* Riquadri */}
                                        <Grid item width="12vw" style={{marginLeft:"2vw", marginTop :"2vw"}}>
                                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "12px", width: "14vw" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", border: "2px solid #007CC1", borderRadius: "5px", width: "100%" }}>
                                              <div style={{ width: "15px", height: "15px", backgroundColor: "#007bff", marginRight: "8px" }}></div>
                                              <div style={{ flex: 1, fontSize: "1vw" }}>Scope 1 = <strong>{report?.scope1.tonCo2}</strong> (tCO2eq)</div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", border: "2px solid #007CC1", borderRadius: "5px", width: "100%" }}>
                                              <div style={{ width: "15px", height: "15px", backgroundColor: "#16497e", marginRight: "8px" }}></div>
                                              <div style={{ flex: 1, fontSize: "1vw" }}>Scope 2 = <strong>{report?.scope2.tonCo2}</strong> (tCO2eq)</div>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {/* Scritta sotto Grafico a Torta e Riquadri */}
                                      <Grid item width="35vw">
                                        <p style={{ fontSize: "0.7vw", textAlign: "left", fontStyle: "italic", marginTop: "1vh" }}>{t("label15Report")}</p>
                                      </Grid>
                                    </Grid>
                                    {/* Divider */}
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    {/* Sezione BarChart */}
                                    <Grid item width="40vw">
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", justifyContent: "center", marginLeft: "2vw" }}>
                                          <p style={{ fontSize: "1vw", textAlign: "left", marginLeft: "2vw" }}>{t("label13Report")}</p>
                                          <BarChart dato1={report?.tonCo2PerMillionSales} dato2={report?.industryAverage} label2={t("benchmark")} label1={t("output")} />
                                          <p style={{ fontSize: "0.7vw", textAlign: "left", fontStyle: "italic", marginLeft: "2vw", marginTop: "1vh" }}>{t("label14Report")}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper square elevation={3} sx={{ backgroundColor: "white", borderRadius: '50px', width: "80vw", padding: "2rem", gap: "1vh" }}  >
                                <Grid container justifyContent="space-around">
                                    <Grid item xs={5}>
                                        <Grid container gap="2vh" direction="column" justifyContent="flex-start" alignItems="center">
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "-5vh", marginLeft: "-2vh", alignItems: "center", gap: "0.5vw" }}>
                                                <CustomWidthTooltip style={{ height: "4vh" }} title={<div>
                                                    <Typography >{t("infoScope1ReportLine1")}</Typography>
                                                    <Typography >{t("infoScope1ReportLine2")}</Typography>
                                                    <Typography >{t("infoScope1ReportLine3")}</Typography>
                                                    <Typography >{t("infoScope1ReportLine4")}</Typography>
                                                </div>}>
                                                    <Button variant="info">
                                                        <InfoOutlinedIcon />
                                                    </Button>
                                                </CustomWidthTooltip>
                                                <p style={{ color: "#007CC1", fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', fontSize: '1.4vw' }}>{t("label6Report")}</p>
                                            </div>
                                            <div style={{ marginTop: "-4vh" }}></div>
                                            {
                                                !report?.scope1.isAverage ? (
                                                    <>
                                                        <ChartItem2 type={t("label7Report")} percentage={report?.scope1.stationaryCombustion ? report?.scope1.stationaryCombustion : ""} />
                                                        <ChartItem2 type={t("label8Report")} percentage={report?.scope1.mobileCombustion ? report?.scope1.mobileCombustion : ""} />
                                                        <ChartItem2 type={t("label9Report")} percentage={report?.scope1.industrialGases ? report?.scope1.industrialGases : ""} />
                                                        <ChartItem2 type={t("label10Report")} percentage={report?.scope1.refrigerantGases ? report?.scope1.refrigerantGases : ""} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid container style={{ gap: "1vw" }}>
                                                            <Grid item xs={3} >
                                                                <Grid container flexDirection="column" alignItems="center" justifyContent="center" gap="2vh">
                                                                    <Grid item sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center", height: "13vh", width: "8vw" }}>
                                                                        <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{t("label7Report")}</p>
                                                                    </Grid>
                                                                    <Grid item sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center", height: "13vh", width: "8vw" }}>
                                                                        <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{t("label8Report")}</p>
                                                                    </Grid>
                                                                    <Grid item sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center", height: "13vh", width: "8vw" }}>
                                                                        <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{t("label9Report")}</p>
                                                                    </Grid>
                                                                    <Grid item sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center", height: "13vh", width: "8vw" }}>
                                                                        <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{t("label10Report")}</p>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={8} sx={{ border: "2px solid lightblue", borderRadius: "10px", padding: "0.5rem", textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                                {t("label21Report")}
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}

                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{ justifyContent: "center" }}>
                                        <Divider orientation="vertical" variant="middle" sx={{ width: "100%" }} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Grid container gap="2vh" direction="column" justifyContent="flex-start" alignItems="center">
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "-5vh", marginLeft: "-2vh", alignItems: "center", gap: "0.5vw" }}>
                                                <CustomWidthTooltip style={{ height: "4vh" }} title={<div>
                                                    <Typography >{t("infoScope2ReportLine1")}</Typography>
                                                    <Typography >{t("infoScope2ReportLine2")}</Typography>

                                                </div>
                                                }>
                                                    <Button variant="info">
                                                        <InfoOutlinedIcon />
                                                    </Button>
                                                </CustomWidthTooltip>
                                                <p style={{ color: "#007CC1", fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', fontSize: '1.4vw' }}>{t("label62Report")}</p>
                                            </div>
                                            <div style={{ marginTop: "-4vh" }}></div>

                                            {
                                                !report?.scope2.isAverage ? (
                                                    <>
                                                        <ChartItem2 type={t("label11Report")} percentage={report?.scope2.electricEnergy ? report?.scope2.electricEnergy : ""} />
                                                        <ChartItem2 type={t("label12Report")} percentage={report?.scope2.warmth ? report?.scope2.warmth : ""} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid container style={{ gap: "1vw" }}>
                                                            <Grid item xs={3} >
                                                                <Grid container flexDirection="column" alignItems="center" justifyContent="center" gap="2vh">
                                                                    <Grid item sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center", height: "13vh", width: "8vw" }}>
                                                                        <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{t("label11Report")}</p>
                                                                    </Grid>
                                                                    <Grid item sx={{ backgroundColor: "#007CC1", borderRadius: "10px", padding: "0.5rem", alignItems: "center", display: "flex", justifyContent: "center", height: "13vh", width: "8vw" }}>
                                                                        <p style={{ color: "white", fontSize: "1vw", fontWeight: "bold", textAlign: 'center', justifyContent: "center" }}>{t("label12Report")}</p>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={8} sx={{ border: "2px solid lightblue", borderRadius: "10px", padding: "0.5rem", textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                                {t("label22Report")}
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>




                            {/* <Paper square elevation={3} sx={{ display: "flex", backgroundColor: "white", textAlign: 'center', borderRadius: '50px', width: "80vw", flexDirection: "column", padding: "2rem", marginTop: "4vh", marginBottom: "2vh" }} >
                                <p style={{ color: "#015F9F", fontWeight: 'bold', fontSize: '1.4vw', marginTop: '-2vh' }}>{t("label19Report")}</p>
                                <Divider light />
                                <div style={{ display: "flex", flexDirection: 'column', gap: "6vh", marginTop: "7vh", alignItems: 'center', justifyContent: "center", marginLeft: "1vw" }}>
                                    {actions.map((a, index) => (
                                        <ActionComponent titleEng={a.technologySolutionEng} titleIta={a.technologySolutionIta} actionEng={a.technologySolutionExplanationEng} actionIta={a.technologySolutionExplanationIta} key={index} currentLanguage={currentLanguage} sfondo={gradazioniDiBlu[index]} />
                                    ))}
                                    <div>
                                        <p style={{ fontSize: "0.8vw", textAlign: "left", fontStyle: "italic" }}>{t("label20Report")}</p>
                                        <p style={{ fontSize: "0.8vw", textAlign: "left", fontStyle: "italic" }}>{t("label23Report")} <a href='https://www.iea.org/data-and-statistics/data-tools/etp-clean-energy-technology-guide' target="_blank">IEA</a></p>
                                    </div>
                                </div>
                            </Paper> */}


                            {/* <Grid m={2}
                            pt={1}
                            borderRadius='50px'
                            container
                            wrap='nowrap'
                            marginTop="-1vh"
                            direction='row'
                            marginBottom="5vh"
                            sx={{ height: "20vh", width: "83vw", backgroundImage: `url(${revise})`, alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                            <Grid item sx={{ marginLeft: '3vw' }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: "1vw" }}>
                                    <a href={OPENES_URL}>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            style={{
                                                color: "#015F9F",
                                                borderColor: "#FFD600",
                                                background: "#FFD600",
                                                fontWeight: 'bold',
                                                fontSize: '0.8vw'
                                            }}
                                        >
                                            Open-es
                                        </Button>
                                    </a>
                                </div>
                            </Grid>
                            <Grid item sx={{ marginLeft: '1vw' }}>
                                <p style={{ color: "white", fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', fontSize: '1vw' }}>{t("label3Report")}</p>
                            </Grid>

                             </Grid> */}

                            <Grid
                                container
                                padding="1vw"
                            
                                
                            >
                                <div style={{border: "1px solid lightblue", padding:"1vw", fontStyle: "italic" }}>{t('disclaimer')}</div>
                            </Grid>
                        </>

                    )
                    }


                </Grid >
                </div>
                </>

                )
}
