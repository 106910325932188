import React from "react";
import Accenture from "../../images/logos/accenture_logo.png"

export const Footer: React.FC = ({ }) => {

    return (
        <footer style={{
            background: "#888888",
            position: "fixed", // Fissa il footer
            left: 0,
            width: "100%", // Occupare tutta la larghezza della finestra
            display: "flex",
            flexDirection: "row",
            gap: "0.5vw",
            justifyContent: "flex-end",
            justifyItems: "flex-end",
            alignItems: "center",
            paddingRight:'1vw',
            bottom:0,
            marginTop: 'auto',
            height:'7vh'
        }}>

            <div style={{ fontSize: "1.8vh", fontWeight: "bold", fontStyle: "italic", color: "white", marginTop: "1.4vh" }}>Powered by</div>
            <img src={Accenture} alt="logo" height='100%' style={{ marginRight: "1.2em",}}></img>

        </footer>
    )
}